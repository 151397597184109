<template>
    <CRow v-if="notifications.length > 0 && show" :xs="{ cols: 1 }">
        <ConfirmDialog ref="confirmDialog"/>
        <CCol :style="bodyHeight">
            <CCol v-for="(el, key) in notifications" :key="key" class="p-2 notificationRow">
                <CRow>
                    <CCol>
                        <CNotification :type-name="typeName" @onDelete="$emit('deleteItem', $event)" :data="el"/>
                    </CCol>
                </CRow>
            </CCol>
        </CCol>
        <CCol xs="12" class="text-center mt-3" v-if="loadingAvailable">
            <CButton size="sm" color="dark" variant="outline" @click="$emit('load')">{{ textBtnMore }}</CButton>
        </CCol>
    </CRow>
</template>

<script>
import ConfirmDialog from '@/components/Modals/ConfirmDialog'
import CNotification from "@/components/CNotification.vue";

const DEF_LIMIT = 5
const MORE_LIMIT = 100
const ICONS = {
    invite: 'cilUserFollow',
    deletedFrom: 'cilUserUnfollow',
    check: 'cilCheckAlt',
    x: 'cilX',
    comment: 'cilCommentBubble',
    pen: 'cilPenNib',
    bell: 'cilBell',
    file: 'cilFileCheck',
    uploaded: 'cilUploadPlace'
}
const COLORS = {
    blue: [0, 93, 157],
    green: [46, 184, 92]
}

export default {
    name: 'Body',
    components: {
        CNotification,
        ConfirmDialog,
    },
    inject: ['toast'],
    props: {
        show: {type: Boolean, default: false},
        typeName: {type: String, default: ''},
        notifications: {type: Array, default: []},
        total: {type: Number, default: 0}
    },
    emits: ['deleteItem'],
    data() {
        return {
            showMore: false,
            limit: DEF_LIMIT,
            bodyHeight: {},
            icons: {
                projects: {
                    approved: {name: 'cilCheckAlt', color: [46, 184, 92]},
                    rejected: {name: '', color: []},
                    signed: {name: '', color: []},
                    pending_signature: {name: '', color: []},
                    created: {name: 'cilUserFollow', color: [46, 184, 92]},
                    deleted: {name: 'cilUserUnfollow', color: [229, 83, 83]},
                    changed: {name: 'cilArrowRight', color: [48, 60, 84]},
                    revoked: {name: '', color: []},
                    completed: {name: '', color: []},
                    uploaded: {name: 'cilUploadPlace', color: [0, 93, 157]},
                    downloaded: {name: '', color: []},
                    user_added: {name: '', color: []},
                    user_removed: {name: '', color: []},
                },
                tasks: {
                    approved: {name: '', color: []},
                    rejected: {name: 'cilX', color: [229, 83, 83]},
                    signed: {name: '', color: []},
                    pending_signature: {name: '', color: []},
                    created: {name: 'cilPlus', color: [0, 93, 157]},
                    deleted: {name: '', color: []},
                    changed: {name: '', color: []},
                    revoked: {name: 'cilActionLeft', color: [229, 83, 83]},
                    completed: {name: ICONS.check, color: COLORS.green},
                    uploaded: {name: ICONS.uploaded, color: COLORS.blue},
                    downloaded: {name: '', color: []},
                    user_added: {name: '', color: []},
                    user_removed: {name: '', color: []},
                },
                documents: {
                    approved: {name: 'cilCheckAlt', color: [46, 184, 92]},
                    rejected: {name: 'cilX', color: [229, 83, 83]},
                    signed: {name: 'cilFileCheck', color: [48, 60, 84]},
                    pending_signature: {name: 'cilFileCheck', color: [48, 60, 84]},
                    created: {name: '', color: []},
                    deleted: {name: '', color: []},
                    changed: {name: '', color: []},
                    revoked: {name: '', color: []},
                    completed: {name: '', color: []},
                    uploaded: {name: 'cilUploadPlace', color: [0, 93, 157]},
                    downloaded: {name: '', color: []},
                    user_added: {name: '', color: []},
                    user_removed: {name: '', color: []},
                },
                system: {
                    approved: {name: '', color: []},
                    rejected: {name: '', color: []},
                    signed: {name: '', color: []},
                    pending_signature: {name: '', color: []},
                    created: {name: '', color: []},
                    deleted: {name: '', color: []},
                    changed: {name: '', color: []},
                    revoked: {name: '', color: []},
                    completed: {name: '', color: []},
                    uploaded: {name: '', color: []},
                    downloaded: {name: '', color: []},
                    user_added: {name: '', color: []},
                    user_removed: {name: '', color: []},
                },
                insights: {
                    approved: {name: '', color: []},
                    rejected: {name: '', color: []},
                    signed: {name: '', color: []},
                    pending_signature: {name: '', color: []},
                    created: {name: '', color: []},
                    deleted: {name: '', color: []},
                    changed: {name: '', color: []},
                    revoked: {name: '', color: []},
                    completed: {name: '', color: []},
                    uploaded: {name: '', color: []},
                    downloaded: {name: '', color: []},
                    user_added: {name: '', color: []},
                    user_removed: {name: '', color: []},
                },
            },
        }
    },
    computed: {
        loadingAvailable() {
            return this.total > this.notifications.length
        },
        notificationList() {
            return this.showMore
                ? this.notifications
                : this.notifications.slice(0, this.limit)
        },
        showBtnViewMore() {
            return this.notifications.length > DEF_LIMIT
        },
        textBtnMore() {
            return this.showMore ? 'Hide' : 'View more'
        },
    },
    watch: {
        show: function (newVal) {
            this.$nextTick(() => {
                this.bodyHeight = newVal ? this.setBodyHeight() : {}
            })
        },
        showMore: function (val) {
            this.$nextTick(() => {
                this.limit = val ? MORE_LIMIT : DEF_LIMIT
                this.bodyHeight = this.setBodyHeight()
            })
        },
    },
    methods: {
        setBodyHeight() {
            let els = document.getElementsByClassName('notificationRow')
            let h = els.length
                ? els[0].offsetHeight * this.limit
                : els[0].offsetHeight * this.notificationList.length
            return {'max-height': h + 'px', 'overflow-y': 'auto'}
        },
        btnStyle(item) {
            let color = this.icons[this.typeName][item.icon]['color']
            return {
                height: '100%',
                color: `rgb(${color.join()})`,
                background: `rgba(${color.join()},0.25)`,
            }
        },
        handleDelete(notification, index) {
            this.$refs.confirmDialog
                .confirm({
                    text: 'Do you want delete message?',
                    info: notification.message,
                    confirmText: 'Delete',
                    cancelText: 'Cancel',
                })
                .then((res) => {
                    if (res) this.deleteNotification(notification, index)
                })
        },
        async deleteNotification(notification, index) {
            notification.loading = true
            await this.$http.topNav
                .deleteNotification(notification.id)
                .then((res) => {
                    this.toast('info', res.data.message)
                    this.$emit('deleteItem', index)
                })
                .catch(({response}) => this.toast('warning', response.data.message))
                .finally(() => (notification.loading = false))
        },
    }
}
</script>

<style scoped>
.notificationRow .date {
    font-size: 10px;
    line-height: 13px;
    color: #b1b7c1;
}

.notificationRow .msg {
    font-size: 14px;
    line-height: 19px;
    color: #303c54;
}
</style>
